import { CloseOutlined } from '@ant-design/icons';
import { addUploadApi } from 'app/services/upload';
import { Modal, Paragraph } from '../components/atoms';
import { emptyState } from '../configs/local-storage';
import { statuses } from '../constants/statuses';
import React from 'react';

const { confirm } = Modal;

interface ShowConfirmParams {
  onCancel?: () => void;
  okText?: string;
  cancelText?: string;
  content?: React.ReactNode;
  okButtonProps?: Record<string, any>;
  cancelButtonProps?: Record<string, any>;
  bodyStyle?: Record<string, any>;
  [key: string]: any;
}

export const defText: any = (
  text: string,
  align: string,
  isPrice: any,
  extraProps: Record<string, any> = {},
  emptyValue: string,
  theme: { colors?: any; isDark?: boolean } = { colors: {}, isDark: false },
  padding: string = '0px'
) => {
  const { colors, isDark } = theme;
  let rowContent = '';

  if (!text) {
    rowContent = emptyValue || '-';
  } else {
    rowContent = text;
    if (isPrice) {
      rowContent = isPrice?.onlyLocale
        ? `$ ${Number(text).toLocaleString()}`
        : `$ ${Number(text).toLocaleString(undefined, { minimumFractionDigits: 2 })}`;
    }
  }

  return (
    <Paragraph
      fz={12}
      mb={0}
      fw={400}
      color={isDark ? '#fff' : colors?.text_colors?.yankees_blue || '#000'} // Fallback to black if undefined
      ellipsis={{ tooltip: { overlayStyle: { maxWidth: '350px', fontSize: '12px' } } }}
      align={align}
      {...extraProps}
      padding={padding}
    >
      {rowContent}
    </Paragraph>
  );
};

export const formatPhone = text => text?.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');

export const convertFileUrl = (url: string) => {
  return url?.includes('https://') ? url : `https://${url}`;
};

export const uploadFile = async (file, setUploadedFile, setUploadLoading) => {
  setUploadLoading(true);
  const callback = await addUploadApi(file);
  setUploadLoading(false);
  if (callback) {
    const uploadedData = callback?.data ? callback?.data[0] : null;
    const uploadedObjFile = { ...uploadedData, url: convertFileUrl(uploadedData?.url) };
    // if you want to collect your images, your default value should be array, otherwise what type do you want
    setUploadedFile(prev => (Array.isArray(prev) ? [...prev, uploadedObjFile] : uploadedObjFile));
  }
  return false;
};

export const showConfirm = (params: ShowConfirmParams = {}, theme = { isDark: false }) => {
  const { isDark } = theme;
  confirm({
    title: (
      <div
        style={{
          background: isDark
            ? 'linear-gradient(126.97deg, rgba(10, 17, 53) 28.26%, rgba(31, 40, 86) 91.2%)'
            : '#002885',
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          cursor: 'pointer',
          padding: '5px 20px',
        }}
        onClick={() => {
          if (params.onCancel) {
            params.onCancel();
          }
          Modal.destroyAll();
        }}
      >
        <CloseOutlined />
      </div>
    ),
    icon: false,
    onCancel() {},
    okText: 'YES',
    cancelText: 'NO',
    maskClosable: true,
    centered: true,
    ...params,
  });
};

export const getBase64 = async (file): Promise<string> =>
  await new Promise((resolve, reject) => {
    if (window.FileReader && file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = reject;
    } else {
      reject(new Error('window error'));
    }
  });

export const blobToBase64 = async blob => {
  return await new Promise(resolve => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
};

export const base64toBlob = (base64String: string) => {
  const contentTypeMatch = base64String.match(/^data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+);base64,/);
  const contentType = contentTypeMatch ? contentTypeMatch[1] : 'application/octet-stream';

  const base64Data = base64String.includes(',') ? base64String.split(',')[1] : base64String;

  const byteCharacters = atob(base64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    // @ts-expect-error
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: contentType });
};

export const convertPrice = price =>
  Number(price || 0).toLocaleString(undefined, {
    minimumFractionDigits: 2,
  });

export const filterOption = (value, option) => {
  const children = Array.isArray(option?.label) ? (option?.label || []).join('') : option?.label;
  return (children || '').toLowerCase().includes(value.toLowerCase());
};

export const getStatusProps = (item: any) => {
  let background: string = '';
  let color: string = '';
  let border: string = '';
  let text = statuses.pending;
  if (item?.serviceComp) {
    background = '#538ED5';
    color = '#FFF';
    border = '#002885';
    text = statuses.complete;
  }

  if (!item?.serviceStart && !item?.serviceStop && !item?.serviceClose && !item?.serviceComp) {
    background = '#ffffff';
    color = '#002885';
    border = '#002885';
    text = statuses.pending;
  }

  if (item?.serviceStart && !item?.serviceStop && !item?.serviceClose && !item?.serviceComp) {
    color = '#000';
    border = '#000';
    background = '#F2F2F2';
    text = statuses.inService;
  }

  if (item?.serviceStop && !item?.serviceClose && !item?.serviceComp) {
    background = '#F2DCDB';
    color = '#7644AD';
    border = '#7644AD';
    text = statuses.onHold;
  }

  if (item?.serviceClose && !item?.serviceComp) {
    background = '#F2F2F2';
    color = '#FF4A4A';
    border = '##FF4A4A';
    text = statuses.review;
  }
  return { background, color, text, border };
};

export const logoutUser = () => {
  emptyState();
  window.location.href = '/sign-in';
};

export const setAllOnClear: any = (form, key, customValue) => {
  setTimeout(() => {
    form.setFieldValue(key, customValue === undefined ? '' : customValue);
  }, 0);
};

export const getUniqueValues = (data, key) => {
  // @ts-expect-error
  return [...new Map(data?.map(item => [item[key], item])).values()];
};
